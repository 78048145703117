<!-- 智惠妇儿 -->
<template>
  <div class="main">
    <div class="header">
      <img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png" />
      <img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
      <span class="header-txt">智惠妇儿</span>
      <!-- 关闭 刷新 -->
      <div class="idx-middlesx-box3">
        <div class="idx-middle-1">
          <img
            class="fh"
            src="@/assets/image/zhfe/icon/fh.png"
            @click="$router.go(-1)"
          />
          <!-- <img class="fh" src="@/assets/image/zhfe/icon/gb.png"/> -->
          <img
            class="sx"
            src="@/assets/image/zhfe/icon/sx.png"
            alt=""
            @click="$router.go(0)"
          />
        </div>
      </div>
    </div>
    <!-- 时间 -->
    <div class="time-box">
      <Date></Date>
    </div>
    <div class="content">
      <div class="left">
        <div class="left_top" @click="clickEdit('ryzcqk')">
          <div class="left_top_title">人员组成情况</div>
          <div class="left_top_number">{{ userNumber }}</div>
          <ul class="left_top_content">
            <li
              class="content_item"
              v-for="item in ryzcqkList"
              :key="item.dataId"
            >
              <div class="item_top">
                <span class="font_style">{{ item.dataDetailed }}</span>
                {{ item.dataField1 }}
              </div>
              <div class="item_bottom">{{ item.dataName }}</div>
            </li>
          </ul>
        </div>
        <div class="left_bottom">
          <div class="left_bottom_title" @click="toYsfs()">议事方式</div>
          <!-- <div class="left_bottom_title">议事方式</div> -->
          <ul class="left_bottom_content">
            <li
              class="content_item"
              v-for="item in ysfsList"
              :key="item.dataId"
            >
              {{ item.dataDetailed }}
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div class="top_title">
          <div class="badge badge_1"></div>
          <div class="badge badge_2"></div>
          <div class="badge badge_3"></div>
          <div class="badge badge_4"></div>
          <div class="headline-wrap">
            <div class="headline">
              <p class="headline-text" @click="toYxal()">优秀案例</p>
              <!-- <p class="headline-text">优秀案例</p> -->
            </div>
          </div>
          <div class="right_content">
            <div class="right_content_slideImg">
              <img
                class="slide_prev"
                @click="imgPrev"
                src="../../assets/image/zhfe/prev.png"
                alt=""
              />
              <img
                class="slide_next"
                @click="imgNext"
                src="../../assets/image/zhfe/next.png"
                alt=""
              />
              <el-carousel
                ref="carouselRef"
                :interval="5000"
                arrow="never"
                indicator-position="none"
              >
                <el-carousel-item
                  style="height: 35vh"
                  v-for="item in yxalimageList"
                  :key="item.dataId"
                >
                  <div class="img">
                    <img :src="item.dataDetailed" alt="" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="right_content_list">
              <li class="list_title">
                <div class="title_left">
                  {{ yxalInfo.dataName ? yxalInfo.dataName + "——" : ""
                  }}{{ yxalInfo.dataDetailed }}
                </div>
                <div class="title_right">
                  <span class="title_right_left" @click="prevPage()">zuo</span>
                  <span class="title_right_center">
                    <span>{{ yxalIndex + 1 }}</span>
                    <span>/</span>
                    <span>{{ yxalList.length }}</span>
                  </span>
                  <span class="title_right_right" @click="nextPage()">you</span>
                </div>
              </li>
              <li class="list_item">
                <div class="item_left">优秀议事项目</div>
                <div class="item_right">
                  {{ yxalInfo.dataField1 }}
                </div>
              </li>
              <li class="list_item">
                <div class="item_left">项目特色</div>
                <div class="item_right">
                  {{ yxalInfo.dataField3 }}
                </div>
              </li>
              <li class="list_item">
                <div class="item_left">议事会成员</div>
                <div class="item_right">
                  {{ yxalInfo.dataField2 }}
                </div>
              </li>
              <li class="list_item">
                <div class="item_left">议事项目推进模式</div>
                <div
                  class="item_right"
                  v-html="
                    yxalInfo.dataField4
                      ? yxalInfo.dataField4.replace(/\n/gm, '<br>')
                      : ''
                  "
                ></div>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框修改部分 -->
    <Dialog
      :dialog="edit_dialog"
      :dialogTitle="dialogTitle"
      :dataList="dialogList"
    ></Dialog>
  </div>
</template>

<script>
import Date from "@/views/commonComponents/date.vue";
import { getWomanMeetList, getNumber } from "@/api/womanMeet";
import Dialog from "./components/dialog.vue";

export default {
  components: {
    Date,
    Dialog,
  },
  data() {
    return {
      edit_dialog: false,
      dialogTitle: "测试",
      dialogList: [],
      ryzcqkQuery: {
        // 人员组成
        dataModule: "fnysh",
        dataTyp: "fnysh-ryzcqk",
        status: 0,
        pageNum: 1,
      },
      ryzcqkList: [],
      ysfsQuery: {
        // 议事方式
        dataModule: "fnysh",
        dataTyp: "fnysh-ysfs",
        status: 0,
      },
      ysfsList: [],
      yxalQuery: {
        // 优秀案例
        dataModule: "fnysh",
        dataTyp: "fnysh-yxal",
        status: 0,
      },
      yxalList: [],
      yxalIndex: 0,
      yxalimageQuery: {
        // 优秀案例-图片
        dataModule: "fnysh-yxal-image",
        dataTyp: "",
      },
      yxalimageList: [],

      fnyshQuery: {
        // 人员组成总数
        dataModule: "fnysh",
        dataTyp: "fnysh-ryzcqk",
      },
      userNumber: 0,
    };
  },
  computed: {
    yxalInfo() {
      return this.yxalList[this.yxalIndex] || {};
    },
  },
  methods: {
    toYsfs() {
      if (this.islogin() !== 1) return;
      this.$router.push("discussWay");
    },
    toYxal() {
      if (this.islogin() !== 1) return;
      this.$router.push("editGoodDemo");
    },
    // 判断是否登录
    islogin() {
      // 1为编辑，2为阅读，0为未登录
      const pass = this.$cookies.get("pass");
      let returndata = 0;
      if (pass == this.readPass || pass == this.editPass) {
        if (this.existSystem()) {
          returndata = 1;
        } else {
          returndata = 2;
        }
      } else {
        returndata = 0;
      }
      return returndata;
    },
    clickEdit(val) {
      if (this.islogin() !== 1) return;
      if (val === "ryzcqk") {
        this.dialogTitle = "人员组成情况";
        this.dialogList = this.ryzcqkList.map((item) => {
          return {
            ...item,
          };
        });
      } else {
        this.dialogTitle = "议事方式";
        this.dialogList = this.ysfsList.map((item) => {
          return {
            ...item,
          };
        });
      }
      this.edit_dialog = true;
    },
    prevPage() {
      console.log("减", this.yxalIndex);
      this.yxalIndex =
        this.yxalIndex === 0 ? this.yxalList.length - 1 : this.yxalIndex - 1;
      // const id = this.yxalList[this.yxalIndex].dataId;
      // this.yxalimageQuery.id = id;
      this._getImgList();
    },
    nextPage() {
      console.log("加", this.yxalIndex);
      this.yxalIndex =
        this.yxalIndex === this.yxalList.length - 1 ? 0 : this.yxalIndex + 1;
      // const id = this.yxalList[this.yxalIndex].dataId;
      // this.yxalimageQuery.id = id;
      this._getImgList();
    },
    imgPrev() {
      console.log(this.$refs.carouselRef);
      this.$refs.carouselRef.prev();
    },
    imgNext() {
      console.log(this.$refs.carouselRef);
      this.$refs.carouselRef.next();
    },
    async _getWomanMeetList(obj) {
      const { rows } = await getWomanMeetList(obj);
      console.log("返回数据", rows);
      switch (obj.dataTyp) {
        case "fnysh-ryzcqk":
          this.ryzcqkList = rows;
          break;
        case "fnysh-ysfs":
          this.ysfsList = rows;
          break;
        case "fnysh-yxal":
          this.yxalList = rows;
          this._getImgList();
          break;
      }
    },
    async _getImgList() {
      console.log("獲取圖片", this.yxalList[this.yxalIndex]);
      const { imgList, dataId } = this.yxalList[this.yxalIndex] || {};
      this.yxalimageQuery.dataTyp = dataId;
      // if (imgList) return
      const { rows } = await getWomanMeetList(this.yxalimageQuery);
      console.log("返回图片数据", this.yxalList[this.yxalIndex], rows);
      this.$nextTick(() => {
        this.yxalimageList = rows;
      });
    },
    async _getNumber(obj) {
      const { data } = await getNumber(obj);
      this.userNumber = data ? data : 0;
    },
  },
  mounted() {
    this._getWomanMeetList(this.ryzcqkQuery);
    this._getWomanMeetList(this.ysfsQuery);
    this._getWomanMeetList(this.yxalQuery);
    this._getNumber(this.fnyshQuery);
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
